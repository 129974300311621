<div class="flex">
  <app-datepicker-monthly
    title="Select Date Range"
    [initialDate]="startOn"
    [minDate]="minDate"
    [maxDate]="maxDate"
    (dateSelectionChange)="startDateChanged($event)"
    >
  </app-datepicker-monthly>

  <div class="to-divider">to</div>

  <app-datepicker-monthly
    [initialDate]="endOn"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [isEndOfMonth]="true"
    (dateSelectionChange)="endDateChanged($event)"
    >
  </app-datepicker-monthly>
</div>
@if (hasInvertedDateSelection) {
  <mat-error class="mat-error">
    Invalid date range selection, please fix
  </mat-error>
}
