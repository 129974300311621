import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
})
export class DialogHeaderComponent {
  @Input() public textAlign = 'center';

  @Output() public closeModal?: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  get headerTextStyle() {
    return {
      textAlign: this.textAlign,
    };
  }

  closeClicked(): void {
    if (this.closeModal) {
      this.closeModal.emit();
    }
  }
}
