import { NameValue } from '../helpers';

export const customerTypeOptions: NameValue[] = [
  {
    value: 'PARTNER',
    name: 'Collected At Partner',
  },
  {
    value: 'ACTIVE',
    name: 'Avios Active Customers',
  },
  {
    value: 'INACTIVE',
    name: 'Avios Inactive Customers',
  },
  {
    value: 'NON_PARTNER',
    name: 'Not Collected At Partner',
  },
];
