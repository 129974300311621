import { Pipe, PipeTransform } from '@angular/core';
import translations from './translation-map';

@Pipe({ name: 'translate' })
export class TranslatePipe implements PipeTransform {
  transform(value: string, group?: string): string {
    const key = group !== undefined ? `${group}.${value}` : value;
    if (translations.has(key)) {
      return translations.get(key);
    }

    return value;
  }
}
