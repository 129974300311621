import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';

import { Dataset } from '../common-api';

@Component({
  selector: 'app-dataset-date-range-picker',
  templateUrl: './dataset-date-range-picker.component.html',
  styleUrls: ['./dataset-date-range-picker.component.scss'],
})
export class DatasetDateRangePickerComponent implements OnInit {
  @Input() dataset: Dataset;

  public startOn: Date;
  public endOn: Date;

  public minDate: Date;
  public maxDate: Date;

  @Output() dateRangeChange: EventEmitter<DateTime[]> = new EventEmitter();

  public hasInvertedDateSelection = false;

  constructor() {}

  ngOnInit(): void {
    this.startOn = DateTime.fromISO(this.dataset.startOn).toJSDate();
    this.endOn = DateTime.fromISO(this.dataset.endOn).toJSDate();

    this.minDate = new Date(this.startOn);
    this.maxDate = new Date(this.endOn);

    this.emitRangeSelection();
  }

  startDateChanged(selectedDate: Date): void {
    this.startOn = selectedDate;
    this.emitRangeSelection();
  }

  endDateChanged(selectedDate: Date): void {
    this.endOn = selectedDate;
    this.emitRangeSelection();
  }

  emitRangeSelection(): void {
    const startDateTime = DateTime.fromJSDate(this.startOn);
    const endDateTime = DateTime.fromJSDate(this.endOn);

    this.hasInvertedDateSelection = endDateTime < startDateTime;

    if (this.hasInvertedDateSelection) {
      return;
    }

    this.dateRangeChange.emit([startDateTime, endDateTime]);
  }
}
