import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfirmDialogComponent } from './confirm-dialog.component';

export interface DialogOptions {
  yesLabel: string;
  noLabel: string;
  width: string;
  message: string;
  title?: string;
}

@Injectable()
export class ConfirmDialogService {
  public options: DialogOptions = {
    yesLabel: 'Yes',
    noLabel: 'No',
    width: '250px',
    title: null,
    message: 'Are you sure?',
  };

  constructor(public dialog: MatDialog) {}

  open(options: Partial<DialogOptions> = {}): Observable<boolean> {
    const data = { ...this.options, ...options };
    const width = data.width;

    return this.dialog
      .open(ConfirmDialogComponent, { width, data })
      .afterClosed()
      .pipe(map((response) => response === 'true'));
  }
}
