import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-basic-page',
  templateUrl: './basic-page.component.html',
  styleUrls: ['./basic-page.component.scss'],
})
export class BasicPageComponent {
  @Input() shadeBackground = false;

  get containerClass(): string {
    return 'container';
  }

  get backgroundClass(): string {
    return this.shadeBackground ? 'dark-background' : 'light-background';
  }
}
