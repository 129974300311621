import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
Highcharts.setOptions({
  lang: { thousandsSep: ',', numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E'] },
});

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatPseudoCheckboxModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxPermissionsModule } from 'ngx-permissions';

import { ConfirmDialogModule } from './confirm-dialog/confirm-dialog.module';
import { AddButtonComponent } from './add-button/add-button.component';
import { BasicPageComponent } from './basic-page/basic-page.component';
import { CardTabNavComponent } from './card-tab-nav/card-tab-nav.component';
import { DatepickerMonthlyComponent } from './datepicker-monthly/datepicker-monthly.component';
import { DatasetDateRangePickerComponent } from './dataset-date-range-picker/dataset-date-range-picker.component';
import { DateRangePickerOptionsComponent } from './date-range-picker-options/date-range-picker-options.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { MatOptionSelectAllComponent } from './mat-option-select-all/mat-option-select-all.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { ContentPlaceholderComponent } from './content-placeholder/content-placeholder.component';
import { ContentPlaceholderSectionComponent } from './content-placeholder-section/content-placeholder-section.component';
import { FiltersComponent } from './filters/filters.component';

import { MetricPipe } from './pipes/metric.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { TranslatePipe } from './pipes/translate/translate.pipe';

@NgModule({
  declarations: [
    AddButtonComponent,
    BasicPageComponent,
    CardTabNavComponent,
    DatepickerMonthlyComponent,
    DatasetDateRangePickerComponent,
    DateRangePickerOptionsComponent,
    DateRangePickerOptionsComponent,
    DialogHeaderComponent,
    MatOptionSelectAllComponent,
    SearchBoxComponent,
    ContentPlaceholderComponent,
    ContentPlaceholderSectionComponent,
    FiltersComponent,
    MetricPipe,
    SanitizeHtmlPipe,
    TranslatePipe,
  ],
  exports: [
    CommonModule,
    AddButtonComponent,
    BasicPageComponent,
    CardTabNavComponent,
    DateRangePickerOptionsComponent,
    DialogHeaderComponent,
    MatOptionSelectAllComponent,
    SearchBoxComponent,
    ContentPlaceholderComponent,
    ContentPlaceholderSectionComponent,
    FiltersComponent,
    MetricPipe,
    SanitizeHtmlPipe,
    TranslatePipe,
    FormsModule,
    HighchartsChartModule,
    ReactiveFormsModule,
    RouterModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatPseudoCheckboxModule,
    MatRadioModule,
    MatSortModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    ConfirmDialogModule,
    NgxPermissionsModule,
    DatasetDateRangePickerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HighchartsChartModule,
    ReactiveFormsModule,
    RouterModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatPseudoCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    ConfirmDialogModule,
    NgxPermissionsModule.forChild(),
  ],
  providers: [
    DecimalPipe,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
    MetricPipe,
    SanitizeHtmlPipe,
    TranslatePipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class SharedModule {}
