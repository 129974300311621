<div class="mat-card-flush">
  <mat-tab-group
    (selectedIndexChange)="tabChanged($event)"
    animationDuration="0ms"
    [class.no-padding]="removePadding"
    >
    @for (tab of tabs; track tab) {
      <mat-tab [label]="tab"></mat-tab>
    }
  </mat-tab-group>
</div>
