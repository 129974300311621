import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';

interface TimeframeOption {
  value: string;
  label: string;
  numDays: number;
}

@UntilDestroy()
@Component({
  selector: 'app-date-range-picker-options',
  templateUrl: './date-range-picker-options.component.html',
  styleUrls: ['./date-range-picker-options.component.scss'],
})
export class DateRangePickerOptionsComponent implements OnInit, AfterViewInit {
  @Output() dateRangeChanged: EventEmitter<DateTime[]> = new EventEmitter();

  public timeframeOptions: TimeframeOption[];
  public form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.timeframeOptions = [
      { value: '30', label: 'Since 30 Days Ago', numDays: 30 },
      { value: '60', label: 'Since 60 Days Ago', numDays: 60 },
      { value: '90', label: 'Since 90 Days Ago', numDays: 90 },
      { value: '365', label: 'Since 1 Year Ago', numDays: 365 },
      { value: 'custom', label: 'Custom Range', numDays: 0 },
    ];

    this.form = this.formBuilder.group({
      timeframe: [this.timeframeOptions[0]],
      startsOn: [],
      endsOn: [],
    });

    this.updateTimeframe();
  }

  ngAfterViewInit() {
    this.form
      .get('timeframe')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(timeFrame => {
        this.updateTimeframe();
      });
  }

  updateTimeframe() {
    if (this.isCustomTimeframe()) {
      return;
    }

    this.startsOn = DateTime.local()
      .minus({ days: this.timeframe.numDays })
      .toJSDate();
    this.endsOn = DateTime.local().toJSDate();

    this.emitDateRange();
  }

  emitDateRange() {
    this.dateRangeChanged.emit([
      DateTime.fromJSDate(this.startsOn),
      DateTime.fromJSDate(this.endsOn),
    ]);
  }

  isCustomTimeframe() {
    return this.timeframe.value === 'custom';
  }

  get timeframe() {
    return this.form.get('timeframe').value;
  }

  get startsOn(): Date {
    return this.form.get('startsOn').value;
  }

  set startsOn(date: Date) {
    this.form.get('startsOn').setValue(date);
  }

  get endsOn(): Date {
    return this.form.get('endsOn').value;
  }

  set endsOn(date: Date) {
    this.form.get('endsOn').setValue(date);
  }
}
