<div class="filters-container">
  <div class="flex container">
    <mat-form-field class="filter-column w-1/4 transparent-form-field">
      <mat-label>Select Partner</mat-label>
      <mat-select [(ngModel)]="filters.partner" (selectionChange)="onFilterChange()">
        @for (partner of partners; track partner) {
          <mat-option [value]="partner">
            {{ partner.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="filter-column w-1/4 transparent-form-field">
      <mat-label>Select Customer Population</mat-label>
      <mat-select [(ngModel)]="filters.customerTypeId" (selectionChange)="onFilterChange()">
        @for (customerTypeOption of customerTypeOptions; track customerTypeOption) {
          <mat-option
            [value]="customerTypeOption.value"
            >
            {{ customerTypeOption.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="filter-column w-1/4 transparent-form-field">
      <mat-label>Select Population Segment</mat-label>
      <mat-select
        [(ngModel)]="filters.populationSegment"
        (selectionChange)="onPopulationSegmentChange()"
        >
        @for (populationSegmentOption of populationSegmentOptions; track populationSegmentOption) {
          <mat-option
            [value]="populationSegmentOption"
            >
            {{ populationSegmentOption.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="filter-column w-1/4 transparent-form-field">
      <mat-label>{{ filters.populationSegment?.name }}</mat-label>
      <mat-select
        [(ngModel)]="filters.populationSegmentValues"
        (selectionChange)="onDebouncedFilterChange()"
        multiple
        >
        <app-mat-option-select-all
          (selectionChange)="onDebouncedFilterChange()"
        ></app-mat-option-select-all>
        @for (populationSegmentValueOption of populationSegmentValueOptions; track populationSegmentValueOption) {
          <mat-option
            [value]="populationSegmentValueOption.id"
            >
            {{ populationSegmentValueOption.name }}
            @if (populationSegmentValueOption.description) {
              <i
                class="cs cs-help-outline info-icon"
                matTooltipPosition="above"
                [matTooltip]="populationSegmentValueOption.description"
              ></i>
            }
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</div>
