import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, ApiEntity } from '@comsig/core';

import { Dataset, Partner, PopulationSegmentOption } from './';

@Injectable({
  providedIn: 'root',
})
export class CommonApiService extends ApiEntity<any> {
  constructor(protected override api: ApiService) {
    super(api);
  }

  dataset(): Observable<Dataset> {
    return this.api.get('dataset');
  }

  partners(): Observable<Partner[]> {
    return this.api.get('partners');
  }

  populationSegmentOptions(): Observable<PopulationSegmentOption[]> {
    return this.api.get('population-segment-options');
  }
}
