<div class="dialog">
  @if (data.title) {
    <h1 mat-dialog-title>{{ data.title }}</h1>
  }
  <div mat-dialog-content class="content">
    <p>{{ data.message }}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button class="confirm-button" mat-dialog-close="false">{{ data.noLabel }}</button>
    <button mat-button class="confirm-button" mat-dialog-close="true" cdkFocusInitial>
      {{ data.yesLabel }}
    </button>
  </div>
</div>
