import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Filters, FiltersApiParams } from '.';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  private filtersSubject = new BehaviorSubject<Filters>(null);

  public filters$ = this.filtersSubject.asObservable();

  constructor() {}

  update(filters: Filters): void {
    this.filtersSubject.next(filters);
  }

  paramsFrom(filters: Filters, includeCustomerTypeId = true): FiltersApiParams {
    const filterApiParams: FiltersApiParams = {
      partnerId: filters.partner.id,
      populationSegmentId: filters.populationSegment.id,
    };

    if (includeCustomerTypeId) {
      filterApiParams.customerTypeId = filters.customerTypeId;
    }

    if (
      filters.populationSegmentValues.length > 0 &&
      filters.populationSegmentValues.length !== filters.populationSegment.valueOptions.length
    ) {
      filterApiParams.populationSegmentOptionId = filters.populationSegmentValues;
    }

    return filterApiParams;
  }

  isNonCustomerTotalPopulation(filters: Filters): boolean {
    return filters.populationSegment?.id !== 'CUSTOMER_WALLET';
  }

  exportFilename(baseName: string, params: FiltersApiParams): string {
    const partner = params.partnerId.replace(/\s+/g, '-').toLowerCase();

    return [baseName, partner].join('_');
  }
}
