<mat-form-field class="transparent-form-field">
  <mat-label>{{ title }}</mat-label>
  <input matInput [value]="monthYearLabel" readonly (click)="picker.open()" />
  <input
    matInput
    [matDatepicker]="picker"
    [formControl]="dateFormControl"
    [min]="minDate"
    [max]="maxDate"
    class="hidden-date-value"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker
    #picker
    startView="year"
    (monthSelected)="monthSelected($event, picker)"
  ></mat-datepicker>
</mat-form-field>
