<form [formGroup]="form" class="flex gap-x-4">
  <mat-form-field>
    <mat-label>Timeframe</mat-label>
    <mat-select formControlName="timeframe">
      @for (timeframeOption of timeframeOptions; track timeframeOption) {
        <mat-option [value]="timeframeOption">
          {{ timeframeOption.label }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <div [hidden]="isCustomTimeframe()">
    <div class="dateRangeLabel">
      {{ startsOn | date: 'M/d/yyyy' }} -
      {{ endsOn | date: 'M/d/yyyy' }}
    </div>
  </div>

  <mat-form-field [hidden]="!isCustomTimeframe()" class="calendar-field">
    <input
      matInput
      formControlName="startsOn"
      [matDatepicker]="startsOnDatePicker"
      [max]="endsOn"
      placeholder="From Date"
      (dateChange)="emitDateRange()"
      />
    <mat-datepicker-toggle matSuffix [for]="startsOnDatePicker"> </mat-datepicker-toggle>
    <mat-datepicker #startsOnDatePicker> </mat-datepicker>
    <mat-error>Enter a valid date</mat-error>
  </mat-form-field>

  <mat-form-field [hidden]="!isCustomTimeframe()" class="calendar-field">
    <input
      matInput
      formControlName="endsOn"
      [matDatepicker]="endsOnDatePicker"
      [min]="startsOn"
      placeholder="To Date"
      (dateChange)="emitDateRange()"
      />
    <mat-datepicker-toggle matSuffix [for]="endsOnDatePicker"> </mat-datepicker-toggle>
    <mat-datepicker #endsOnDatePicker> </mat-datepicker>
    <mat-error>Enter a valid date</mat-error>
  </mat-form-field>

  <div class="flex-1"></div>
</form>
